div.topic {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 24px;
}

.gotham {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  text-transform: uppercase;
}

.mapboxgl-ctrl-bottom-right {
  opacity: 0;
}

.mapboxgl-ctrl-bottom-left {
  opacity: 0;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.gradient {
  background: rgb(252, 251, 254);
  background: linear-gradient(
    90deg,
    rgba(252, 251, 254, 1) 0%,
    rgba(134, 98, 188, 1) 100%
  );
}
