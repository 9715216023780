main.human {
  padding: 100px 0px;
  min-height: 100vh;
  z-index: 4;
  left: 0px;
  width: 100vw;
  max-width: calc(100vw - 15px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;

  position: relative;
  align-items: center;
  background-color: rgb(230, 230, 230);
  font-family: 'Gotham SSm A', 'Gotham SSm B';
}
